<template>
  <div class="menu_box">
    <div class="home_menu center">
      <!-- <div
        :key="i"
        v-for="(item, i) in menuList"
        :class="
          item.title == checked ? 'home_menu_item_action' : 'home_menu_item'
        "
        @click="toOtherPage(item)"
      >
        {{ item.title }}
      </div> -->
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item index="1">首页</el-menu-item>
        <el-menu-item index="50">疆煤宝服务平台</el-menu-item>
        <el-menu-item index="11">邀约交易</el-menu-item>
        <el-submenu index="2">
          <template slot="title">市场化交易</template>
          <el-menu-item index="21">挂牌采购</el-menu-item>
          <el-menu-item index="22">挂牌销售</el-menu-item>
          <el-menu-item index="31">竞价采购</el-menu-item>
          <el-menu-item index="32">竞价销售</el-menu-item>
          <el-menu-item index="33" v-if="testModuls === 'true' ? true : false"
            >拼团交易</el-menu-item
          >
          <el-menu-item index="34" v-if="logisticsModule === 'true' ? true : false"
            >物流竞价</el-menu-item
          >
        </el-submenu>
        <!-- <el-submenu index="3">
          <template slot="title">竞价</template>
          <el-menu-item index="31">竞价采购</el-menu-item>
          <el-menu-item index="32">竞价销售</el-menu-item>
        </el-submenu> -->
        <el-menu-item index="9">资讯平台</el-menu-item>
        <el-menu-item index="10">数据中心</el-menu-item>
        <el-menu-item index="4">中长期合同管理</el-menu-item>
        <!-- <el-menu-item index="5">矿企申报</el-menu-item> -->
        <el-menu-item index="6">煤交保供</el-menu-item>
        <el-menu-item index="7" v-if="ifOpenFwdt">服务大厅</el-menu-item>
        <el-menu-item index="8">关于我们</el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { baseUrlBySJDP, baseUrlBySNEWS } from "@/config";
import * as types from "@/store/action-types";
export default {
  data() {
    return {
      testModuls: process.env.VUE_APP_OPEN_TEST_MODULS,
      logisticsModule: process.env.VUE_APP_OPEN_LOGISTICS,
      activeIndex: "1",
      checked: false,
      ifOpenFwdt: false, // 是否开启服务大厅
      menuList: [
        {
          title: "首页",
          limit: "all",
          index: 1,
        },
        {
          title: "挂牌采购",
          index: 2,
          path: "/mall",
          query: {
            selectTradeType: "1",
            selectOrderType: "2",
          },
          limit: "shinglepurchase",
        },
        {
          title: "挂牌销售",
          path: "/mall",
          index: 3,
          query: {
            selectTradeType: "1",
            selectOrderType: "1",
          },
          limit: "shinglemarket",
        },
        {
          title: "竞价销售",
          index: 4,
          path: "/mall",
          query: {
            selectTradeType: "2",
            selectOrderType: "1",
          },
          limit: "biddingmarket",
        },
        {
          title: "竞价采购",
          path: "/mall",
          index: 5,
          query: {
            selectTradeType: "2",
            selectOrderType: "2",
          },
          limit: "biddingpurchase",
        },
        {
          title: "邀约交易",
          path: "/Invitation",
          index: 11,
          limit: "biddingpurchase",
        },

        {
          title: "服务大厅",
          path: "/financial",
          index: 6,
          id: "14",
          query: {
            id: "14",
            title: "服务大厅",
          },
          limit: "financial",
        },
        // {
        //   title: "物流服务",
        //   path: "/financial",
        //   index: 7,
        //   query: {
        //     id: "15",
        //     title: "物流服务",
        //   },
        //   limit: "logistics",
        // },
        {
          title: "关于我们",
          path: "/aboutus",
          index: 7,
          query: {
            id: "16",
            title: "关于我们",
          },
          limit: "aboutus",
        },
        {
          title: "中长期合同",
          path: "/MediumData",
          index: 8,
          query: {
            id: "17",
            title: "中长期合同",
          },
          limit: "MediumData",
        },
      ],
    };
  },
  watch: {
    $route: {
      handler(val, onlval) {},
      deep: true,
    },
  },
  mounted() {
    // this.autherTo();
    // this.checked= Vue.ls.get("title")
    let ifOpenFwdt = Vue.ls.get(types.IF_OPEN_FWDT);
    console.log("------------------", ifOpenFwdt);
    if (ifOpenFwdt != undefined && ifOpenFwdt != "" && ifOpenFwdt) {
      // 开启服务大厅
      this.ifOpenFwdt = ifOpenFwdt;
    }
    let a = localStorage.getItem("keyP");
    if (!a) {
      a = "1";
    }
    this.activeIndex = a;
  },
  methods: {
    handleSelect(key, keyPath) {
      let config = {};
      localStorage.setItem("keyP", key);
      if (key == 1) {
        config = {
          path: "/",
          query: {},
          limit: "all",
        };
      }
      if (key == 33) {
        config = {
          path: "/piecing",
        };
      }
      if (key == 34) {
        config = {
          path: "/LogisticsBidding",
        };
      }
      if (key == 11) {
        config = {
          path: "/Invitation",
          index: 11,
          limit: "biddingpurchase",
          query: {
            type: 3,
          },
        };
      }
      if (key == 21) {
        config = {
          path: "/mall",
          query: {
            selectTradeType: "1",
            selectOrderType: "2",
          },
          limit: "shinglepurchase",
        };
      }
      if (key == 22) {
        config = {
          title: "挂牌销售",
          path: "/mall",
          index: 3,
          query: {
            selectTradeType: "1",
            selectOrderType: "1",
          },
          limit: "shinglemarket",
        };
      }
      if (key == 31) {
        config = {
          title: "竞价采购",
          path: "/mall",
          index: 5,
          query: {
            selectTradeType: "2",
            selectOrderType: "2",
          },
          limit: "biddingpurchase",
        };
      }
      if (key == 32) {
        config = {
          title: "竞价销售",
          index: 4,
          path: "/mall",
          query: {
            selectTradeType: "2",
            selectOrderType: "1",
          },
          limit: "biddingmarket",
        };
      }
      if (key == 4) {
        config = {
          title: "中长期合同",
          path: "/MediumData",
          index: 8,
          query: {
            id: "17",
            title: "中长期合同",
          },
          limit: "MediumData",
        };
      }

      if (key == 6) {
        config = {
          title: "煤交保供",
          path: "/exchange",
          index: 11,
          // query: {
          //   id: "17",
          //   title: "中长期合同",
          // },
          limit: "exchange",
        };
      }

      if (key == 7) {
        config = {
          title: "服务大厅",
          path: "/financial",
          index: 6,
          id: "14",
          query: {
            id: "14",
            title: "服务大厅",
          },
          limit: "financial",
        };
      }
      if (key == 8) {
        config = {
          title: "关于我们",
          path: "/aboutus",
          index: 7,
          query: {
            id: "16",
            title: "关于我们",
          },
          limit: "aboutus",
        };
      }
      if (key == 9) {
        window.open(baseUrlBySNEWS);
        return false;
        // const token = Vue.ls.get(types.ACCESS_TOKEN);
        // const userId = Vue.ls.get(types.userId);
        // if (
        //   token != undefined &&
        //   token != "" &&
        //   userId != undefined &&
        //   userId != "null" &&
        //   userId != ""
        // ) {
        //   window.open(
        //     baseUrlBySNEWS + "#/login?token=" + token + "&userId=" + userId
        //   );
        // } else {
        //   window.open(baseUrlBySNEWS);
        // }
        // return false;
      }
      if (key == 10) {
        // 数据中心跳转
        const token = Vue.ls.get(types.ACCESS_TOKEN);
        const userId = Vue.ls.get(types.userId);
        if (
          token != undefined &&
          token != "" &&
          userId != undefined &&
          userId != "null" &&
          userId != ""
        ) {
          window.open(
            baseUrlBySJDP + "#/login?token=" + token + "&userId=" + userId
          );
        } else {
          window.open(baseUrlBySJDP);
        }
        return false;
      }
      if(key == 50) {
        config = {
          path: "/coal/service/platform",
          index: 50,
        };
      }
      this.$router.push(config);
      //   this.activeIndex=key
    },
    toOtherPage(data) {
      this.checked = data.title;
      // Vue.ls.set("title", this.checked)
      // if (!this.beforhandle(data.limit)) return;
      this.$router.push({
        path: data.path,
        query: data.query ? data.query : {},
      });
    },
    autherTo() {
      const path = this.$route.path;
      const whiteList = ["/goodsDetail", "/mall"];
      let menu = this.menuList.filter((item) => {
        return item.path == path;
      });
      // debugger
      if (whiteList.includes(path))
        return (this.checked =
          (this.$route.query.selectTradeType == 1 ? "挂牌" : "竞价") +
          (this.$route.query.selectOrderType == 1 ? "销售" : "采购"));
      if (menu.length == 0) return (this.checked = "首页");
      if (menu.length == 1) return (this.checked = menu[0].title);
      if (this.$route.query.title)
        return (this.checked = this.$route.query.title);
    },
  },
};
</script>

<style lang="scss" scoped>
.menu_box {
  background: #2c33c7;
  z-index: 999;
  width: 100%;
}
::v-deep .el-menu::after,
.el-menu::before {
  display: none !important;
}
@media (max-width: 1000px) {
  .home_menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #2c33c7 !important;
    width: 1260px;
    .home_menu_item {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 500;
      cursor: pointer;
    }

    .home_menu_item_action {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 600;
      cursor: pointer;
      font-weight: bold;
    }
  }
}
@media (min-width: 1000px) {
  .home_menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #2c33c7 !important;
    width: 1440px;
    .home_menu_item {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 500;
      cursor: pointer;
    }

    .home_menu_item_action {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 600;
      cursor: pointer;
      font-weight: bold;
    }
  }
}

::v-deep .el-menu-item {
  /* width: 130px; */
  padding: 0 40px;
  text-align: center;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #fff;
}

::v-deep .el-submenu__title {
  font-size: 18px !important;
  color: #fff !important;
  border-bottom: none !important;
}

::v-deep .el-menu.el-menu--horizontal {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: none;
  background: #2c33c7 !important;
}

::v-deep .is-active {
  color: #fff !important;
  border-bottom: 2px solid #fff !important;
  background: #0000b5 !important;
  font-weight: bolder;
}
::v-deep .el-submenu__title:hover {
  background: #0000b5 !important;
}
::v-deep .el-menu-item:hover {
  background: #0000b5 !important;
  color: #fff !important;
}
</style>
